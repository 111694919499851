.coin-img {
    width: 150px;
    height: 150px;
}

.radio-container img {
    border: 10px solid transparent;
    cursor: pointer;
}

.radio-container input {
    display: none;
}

.radio-container img:hover {
    border: 10px solid #ff7900;
    border-radius: 50%;
}

.radio-container input:checked ~ img {
    border: 10px solid #ff7900;
    border-radius: 50%;
}
