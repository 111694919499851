#root {
    /* Porque tem regra do root aqui?*/
    margin: 0;
    padding: 0;
    text-align: center;
}
  
.home-container {
    margin: 5rem 10rem 10rem 10rem;
}
  
h1,
h3 {
    color: #00593a;
}

h1 span {
    color: #ed7227;
}

.button-coin-form {
    color: #ed7227;
    background-color: transparent;
    border: 2px solid #ed7227;
    border-radius: 50px;
    padding: 0 50px;
    height: 50px;
}

.button-coin-form:hover {
    color: #00593a;
    border: 2px solid #00593a;
}

.welcome-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.5rem;
}

.boards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.coin-amount-wrapper {
    border-radius: 20px;
    width: 580px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;

    -webkit-box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
}

.coin-amount-column-wrapper {
    display: flex;
}

.coin-display {
    width: 100%;
}

.coin-display img {
    width: 80%;
}

.coin-display h4 {
    color: #00593a;
    font-size: 3rem;
    font-weight: bold;
}

.coin-amount-wrapper h3,
h4 {
    margin: 1rem 0;
}

.coin-amount-wrapper h3 {
    text-align: justify;
    font-size: 2.4rem;
}

.coin-amount-wrapper h3 span {
    color: black;
}

.last-transaction {
    border-radius: 20px;
    width: 750px;
    display: flex;
    flex-direction: column;

    -webkit-box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 6px 20px 11px rgba(0, 0, 0, 0.18);
    justify-content: flex-start;
    text-align: center;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
}

.last-transaction tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex;
    justify-content: space-around;
    min-height: 4.5rem;
}

.last-transaction td {
    width: 100%;
}

.last-transaction .value,
.last-transaction .data {
    width: 60%;
}

.last-transaction .data {
    padding-left: 20px;
}

.last-transaction .remetente {
    width: 120%;
}

.last-transaction-header {
    background-color: #d9d9d9;
    border-radius: 20px 20px 0 0;
}

.last-transaction .value {
    display: flex;
    align-items: center;
}

.last-transaction .value img {
    width: 40px;
    height: 40px;
}

.sent-coins {
    color: black;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 20px;
}

/* Estilize a tabela Sent Coins */
.sent-coins-table {
    border-collapse: collapse;
    margin-top: 10px; /* Espaçamento superior para separar da tabela acima */
}

.sent-coins-table th,
.sent-coins-table td {
    border: 1px solid #ddd; /* Borda para células */
    padding: 8px;
    text-align: left;
}

.received-items {
    color: black;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 20px;
}

.received-items-table {
    border-collapse: collapse;
    margin-top: 10px; /* Espaçamento superior para separar da tabela acima */
}

.received-items-table th,
.received-items-table td {
    border: 1px solid #ddd; /* Borda para células */
    padding: 8px;
    text-align: left;
}

.btn-excluir {
    background: transparent;
    display: contents;
}

@media (max-width: 1024px) {
    .boards {
        scale: 80%;
        left: 0.4rem;
    }

    .coin-amount-wrapper {
        width: 495px;
    }

    .last-transaction {
        width: 595px;
        font-size: 1.5rem;
    }

    .home-container {
        margin: 2rem;
    }

    .welcome-bar {
        margin-bottom: 1.5rem;
        top: 5rem;
        position: relative;
        left: -0.5rem;
    }
}

@media (max-width: 720px) {
    .boards {
        flex-direction: column;
        scale: 80%;
        position: relative;
        left: 2.4rem;
        max-width: 83vw;
    }

    .coin-amount-wrapper {
        width: 495px;
    }

    .last-transaction {
        width: 595px;
        font-size: 1.5rem;
    }

    .home-container {
        margin: 2rem;
    }

    .welcome-bar {
        margin-bottom: 0rem;
        top: 4rem;
        position: relative;
        left: -0.5rem;
    }

    /* Resto do CSS para a tabela Sent Coins */
    .sent-coins {
        overflow-x: auto; /* Adiciona uma barra de rolagem horizontal */
        max-width: 100vw; /* Largura máxima de 100vw */
    }

    /* Resto do CSS para a tabela Sent Coins */
    .sent-coins-table {
        width: 100%; /* Largura de 100% para preencher a div pai */
        border-collapse: collapse;
        margin-top: 10px;
    }

    .sent-coins-table th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .sent-coins-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }
}

@media (max-width: 440px) {
    .boards {
        flex-direction: column;
        scale: 60%;
        position: relative;
        left: -3.8rem;
        max-width: 100vw;
    }

    .coin-amount-wrapper {
        width: 495px;
    }

    .last-transaction {
        width: 595px;
        font-size: 1.5rem;
        position: relative;
        top: 3rem;
    }

    .home-container {
        margin: 0;
    }

    .welcome-bar {
        margin-bottom: -1.5rem;
        top: 8rem;
        position: relative;
        left: -0.5rem;
    }
}
