/* Navbar.css */
.navbar {
	width: 100vw;
	height: 6rem;
	font-size: 1.2rem;
	background-color: transparent;
	color: white;
	display: flex;
	justify-content: space-around;
	align-items: center;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-button {
	display: none;
	border-radius: 8px;
	border: 1px solid transparent;
	padding: 0.6em 1.2em;
	font-size: 1em;
	font-weight: 500;
	font-family: inherit;
	background-color: transparent;
	cursor: pointer;
	transition: border-color 0.25s;
}

.nav-button:focus {
	outline: none;
}

.nav-button:hover {
	border-color: transparent;
}
  
.navbar-text {
	display: flex;
	align-items: center;
	color: gray;
	position: relative;
	left: -12%;
}

.navbar img {
	width: 80px;
	margin-right: 10px;
	font-family: "Co Headline";
	fill: #00593a;
}
  
.navbar a {
    gap: 10px;
    text-decoration: none;
    margin-right: 20px;
    &:hover {
      color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
    }
}
  
.navbar a:last-child {
    margin-right: 0;
}

.loja-link {
    color: #00593a; /* Cor padrão */
    &:hover {
      color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
    }
}

.cart-link {
    color: #00593a; /* Cor padrão */
    &:hover {
      color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
    }
}

.login-link {
    color: #00593a; /* Cor padrão */
    &:hover {
      color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
    }
}
  
  /* Estilo quando o menu está aberto (usando a classe menu-open) */
.navbar-menu.menu-open {
    display: block;
    width: 20rem;
    /* Adicione outros estilos de animação, posicionamento etc., conforme necessário */
}

@media (max-width: 768px) {
    .nav-button {
    	display: block; /* Mostrar o botão do hambúrguer em telas menores ou iguais a 780px */
    }

    .navbar-menu {
    	display: none; /* Mostrar o menu em telas menores ou iguais a 780px */
    }

    .navbar-text {
    	left: 3%;
    }
}
  
@media (max-width: 440px) {
    .navbar {
		padding: 10px 0px;
    }

    .navbar-text {
		left: -2%;
    }
  
    .navbar-menu {
		position: relative;
		left: -1%;
    }

    .loja-link {
		color: #00593a; /* Cor padrão */
		width: 4rem;
		left: -1.5rem;
		position: relative;
		&:hover {
		color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
		}
    }

    .cart-link {
		color: #00593a; /* Cor padrão */
		width: 4rem;
		position: relative;
		left: -3.5rem;
		&:hover {
		color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
		}
    }

    .login-link {
		color: #00593a; /* Cor padrão */
		display: flex;
		width: 4rem;
		&:hover {
		color: #ff5100; /* Cor laranja quando o mouse estiver sobre o link */
		}
    }
}
