/* Footer.css */

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: #606060;
    margin-top: 100px;
    padding: 10px 0;
    background-image: url("../../assets/esquerda-1.svg"),
      url("../../assets/esquerda.svg"), url("../../assets/direita.svg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 29% 115%, 19% 100%, 177% 127%;
}
  
  .container-footer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.info-section p,
.contact-section p {
    line-height: 0.6; /* Ajuste este valor conforme necessário */
}
  
/* Estilos para cada coluna */
.logo-section {
    text-align: center; /* Alinha o texto ao centro */
}

.logo-section img {
    width: 200px; /* Ajuste este valor conforme necessário */
}
  
.info-section {
    display: flex;
    flex-direction: column; /* Alinha os elementos verticalmente */
    align-items: flex-start; /* Alinha os elementos à esquerda */
}
  
.grouped-info {
    display: flex;
    text-align: left;
}
  
.contact-section {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.contact-heading {
    font-weight: bold; /* Deixa o texto em negrito */
    color: #ff6600; /* Cor laranja */
}
  
.contact-section ul {
    list-style: none; /* Remove os marcadores da lista */
    padding: 0; /* Remove o preenchimento padrão da lista */
}
  
.contact-section ul li {
    margin-bottom: 10px; /* Espaçamento entre os itens da lista */
}

.social-section {
    display: flex;
}

.social-section {
    display: flex;
    flex-direction: row; /* Alinha os ícones horizontalmente */
    justify-content: center; /* Alinha os ícones ao centro horizontalmente */
    margin-top: 10px; /* Adicione margem superior para separar os ícones dos contatos */
}
  
  /* Footer.css ou seu arquivo de estilos */
.social-icon {
    color: #ff6600; /* Defina a cor desejada para os ícones */
    font-size: 24px; /* Defina o tamanho desejado para os ícones */
    margin: 5px; /* Adicione margem para separar os ícones */
}

.copyright-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.privacity {
    font-weight: normal;
    color: black;
}
  
a:hover .privacity {
    text-decoration: underline;
    color: #ff6600;
}

.phone-number {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

@media (max-width: 1024px) {
    .logo-section img {
      padding-left: 40%;
    }
    .copyright-section {
      padding-left: 33%;
    }
}

@media (max-width: 768px) {
    .container-footer {
      justify-content: space-evenly;
      padding-bottom: 0px;
    }
    .logo-section img {
      padding-left: 10%;
    }
    .copyright-section {
      padding-left: 15%;
    }
}

@media (max-width: 440px) {
    .footer {
        margin-top: 5%;
        padding-bottom: 0px;
        background-size: 62% 180%, 25% 150%, 107% 137%;
    }

    .contact-heading {
        font-size: 16px !important;
    }

    .info-section p {
        line-height: 0;
        font-size: 11px;
    }

    .contact-section p {
        line-height: 0;
        font-size: 11px;
    }

    .container-footer {
        position: relative;
        gap: 10%;
        right: 0;

        padding-bottom: 6%;
        flex-direction: column;
        max-width: 50vw;
    }

    .logo-section {
        position: relative;
        text-align: center;
        scale: 100%;
    }

    .logo-section img {
        position: relative;
        padding-left: 0%;
        left: -32%;
    }

    .info-section {
        top: 1rem;
        position: relative;
    }

    .contact-section {
        position: relative;
        top: 2rem;
    }

    .copyright-section {
        padding-left: 10%;
        scale: 80%;
        flex-direction: column;
    }
}
  