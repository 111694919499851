.login-container {
	background-color: whitesmoke;
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
}

.login-box {
	position: relative;
	top: 10rem;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 456px;
	height: 500px;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.login-box::after {
	content: "";
	background-color: white;
	position: absolute;
	width: 456px;
	height: 443px;
	z-index: -1;
}

.login-form {
	display: flex;
	position: relative;
	gap: 30px;
	flex-direction: column;
}

.login-butom {
	margin-top: 2rem;
	padding: 10px;
	background-color: #ff7900;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 4px;
	width: 380px;
	height: 40px;
	cursor: pointer;
}

.login-butom:hover {
	background-color: #00593A;
}

.login-label {
	display: flex;
	width: 380px;
	height: 40px;
	flex-direction: column;
	gap: 10px;
}

.login-input {
	display: flex;
	width: 380px;
	height: 40px;
	background-color: #f7f7f7;
	border: 1px solid transparent;
}

.new-acount {
	display: flex;
	justify-content: center;
	position: relative;
	top: 30%;
}
.login-title{
	display: flex;
  font-size: 50px;
	position: relative;
	
}
.login-text {
	color: #00593A;
	max-width: 40%;
	max-height: 50%;
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	left: 10%;
	top: 20%;
}

.text {
	color: #000;
	display: flex;
	text-align: justify;
	position: relative;
}
.errmsg{
	color: red;
}
@media (max-width: 1180px){
	.text{
    width: 33.5rem;
    top: 2rem;
    font-size: 1.5rem;
		left: -6rem;
	}
	.login-title{
    left: 37%;
    transform: translate(-50%, 22%);
    top: 4rem;

	}
}
@media (max-width: 1024px) {
	.login-box {
    top: 0rem;
    width: 465px;
    height: 404px;
		scale: 160%;
    left: 17rem;
}
	.login-title{
    left: 116%;
    transform: translate(-50%, 22%);
    font-size: 4rem;
    width: 38rem;
    top: 12rem;
	}
	.login-text {
		left: 4%;
    top: -3%;
	}
	.text{
    width: 58.5rem;
    top: 10rem;
    font-size: 2rem;
		left: 0rem;
	}
	.login-container {
	flex-direction: column;
	}
}
@media (max-width: 1024px) and (max-height: 768px) {
	.login-box {
		top: 11rem;
    width: 455px;
    height: 267px;
    scale: 100%;
    left: 5rem;

	}
	.login-title {
			left: 185%;
			transform: translateX(-50%);
			font-size: 2rem;
			width: 19rem;
			top: -6rem;
	}
	.login-text {
			left: 4%;
			top: -3%;
	}
	.text {
		width: 25.5rem;
    top: -8rem;
    font-size: 1.5rem;
    left: 130%;
	}
	.login-container {
			flex-direction: column;
	}
}
@media (max-width: 820px) {
	.login-box {
    top: 0rem;
    width: 465px;
    height: 404px;
    scale: 116%;
    left: 10rem;
}
	.login-title{
    left: 116%;
    transform: translate(-50%, 22%);
		font-size: 2rem;
		width: 22rem;
		top: 5rem;
	}
	.login-text {
		left: 4%;
    top: -3%;
	}
	.text{
		width: 46rem;
		top: 5rem;
		font-size: 1.5rem;
	}
	.login-container {
	flex-direction: column;
	}
}
@media (max-width: 768px) {
	.login-box {
    top: 0rem;
    width: 465px;
    height: 404px;
    scale: 116%;
    left: 9rem;
}
	.login-title{
    left: 128%;
    transform: translate(-50%, 22%);
		font-size: 2rem;
		width: 22rem;
		top: 5rem;
	}
	.login-text {
		left: 4%;
    top: -3%;
	}
	.text{
		width: 44rem;
		top: 5rem;
		font-size: 1.5rem;
	}
	.login-container {
	flex-direction: column;
	}
}
@media (max-width: 430px) {
	.login-box {
    top: 0rem;
    width: 427px;
    height: 404px;
    scale: 88%;
		left: 0rem;
}
	.login-title{
    left: 128%;
    transform: translate(-50%, 22%);
		font-size: 2rem;
		width: 22rem;
	}
	.login-text {
		left: 4%;
    top: -3%;
	}
	.text{
		width: 24rem;
	}
	.login-container {
	flex-direction: column;
	}
}
@media (max-width: 412px) {
	.login-box {
    top: 0rem;
    width: 427px;
    height: 404px;
    scale: 84%;
		left: -0.5rem
}
	.login-title{
    left: 128%;
    transform: translate(-50%, 22%);
		font-size: 2rem;
		width: 22rem;
	}
	.login-text {
		left: 4%;
    top: -8%;
	}
	.text{
		width: 24rem;
		font-size: 1.2rem;
	}
	.login-container {
	flex-direction: column;
	}
}
@media (max-width: 390px) {
	.login-box {
    top: 0rem;
    width: 387px;
    height: 404px;
    z-index: 1;
    scale: 77%;
}
	.login-title{
    left: 128%;
    transform: translate(-50%, 22%);
		font-size: 2rem;
		width: 22rem;
	}
	.login-text {
		left: 4%;
    top: -3%;
	}
	.text{
		width: 22rem;
	}
	.login-container {
	flex-direction: column;
	}
}